
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { ref, computed, onMounted, defineComponent } from 'vue';
import { Actions } from '@/store/enums/store.enums';

import { ElMessageBox, ElMessage } from 'element-plus';

import ApiService from '@/core/services/ApiService';
import isEmptyObject from '@/core/helpers/isEmptyObject';
import doesObjectsEqual from '@/core/helpers/doesObjectsEqual';

import BasePre from '@/core/components/base/BasePre.vue';
import FormPage from '@/view/pages/common/users/FormPage.vue';

export default defineComponent({
  name: 'users-edit',
  components: {
    FormPage,
    BasePre,
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const entryData = ref({})
    const entryDataForComparing = ref({})
    const formRefParent = ref(null)
    const buttonsLoading = ref(false)

    /**
     * Call the API for an users single entry.
     * @returns {object|null}.
     */
    const requestEntry = async () => {
      ApiService.setHeader()

      return await ApiService.get(`/admin/users/${route.params.id}`)
        .then(response => response.data)
        .catch(error => {
          console.error('> run requestEntry > Doesnt passed', error)
          return null
        })
    }


    /**
     * Form changes comparing.
     */
    const formsAreEqual = computed(() => {
      return doesObjectsEqual(entryData.value, entryDataForComparing.value)
    })


    /**
     * Get an users single entry.
     */
    const getEntry = async () => {
      if (route.params.id) {
        const data = await requestEntry()
        // console.log('>>>> requestEntry', data)
        entryData.value = Object.assign({}, data)
        // console.log('>__ getEntry entryData.value', entryData.value)
        entryDataForComparing.value = Object.assign({}, entryData.value)
      }
    }

    /*********************
     * UPLOAD START
     *********************/

    /**
     * Requesting to the server
     * for images uploading.
     * @param {object} Image file
     * @returns {object|null}.
     */
    const requestPhotoToServer = async file => {
      const photoId = await store.dispatch(
        Actions.UPLOAD_PHOTO,
        file
      )
      console.log('> photoId', photoId)
      return photoId
    }

    /**
     * Sending photo to server.
     * @param {object} The entryData object
     * @returns {number}.
     */
    const sendPhotoToServer = async photo => {
      const photoId = await requestPhotoToServer(photo)

      if (photoId) {
        return photoId
      }
      return null
    }

    /*********************
    * UPLOAD END
    *********************/

    /*********************
    * FORM ACTIONS START
    *********************/

    /**
     * Form validation.
     * Calling the child method
     * and get the result of the validation.
     * @returns {boolean}.
     */
    const validateForm = () => {
      const result = formRefParent.value.validateForm()
      return result
    }

    /**
     * Request to save the entry data object.
     * @param {object} Entry data object.
     * @returns {boolean}.
     */
    const requestSaveForm = async data => {
      // console.log('> run requestSaveForm', data);
      ApiService.setHeader();
      return await ApiService.post(`/admin/users/${route.params.id}`, data)
        .then(() => true)
        .catch(error => {
          ElMessage({
            offset: 20,
            type: 'warning',
            message: 'Не все поля заполнены',
          });
          return false;
        });
    };

    const saveButtonClick = () => {
      // console.log('saveButtonClick');
      formRefParent.value.saveForm();
    };

    /**
     * Entry saving.
     * Validation checking.
     * Preparation of the sending object.
     * Calling the server requesting function.
     */
    const saveForm = async (flag, data) => {
      buttonsLoading.value = true;
      if (flag) {
        // console.log('> saveForm >> data', data);
        if (data.photo && data.photo[0] && data.photo[0].raw) {
          data.photo = await sendPhotoToServer(data.photo[0]);
        } else if (data.photo && data.photo[0] && data.photo[0].id) {
          // console.log('> else >> photo');
          data.photo = data.photo[0].id;
        }
        const resultRequestSaveForm: boolean = await requestSaveForm(data);

        if (resultRequestSaveForm) {
          ElMessage({
            offset: 50,
            type: 'success',
            message: 'Пользователь сохранён',
          });
        } else {
          ElMessage({
            offset: 50,
            type: 'warning',
            message: 'Ошибка сохранения',
          });
        }
        buttonsLoading.value = false;
      } else {
        buttonsLoading.value = false;
      }
    };

    /**
     * Reseting the form.
     * SHowing up the popup for asking the action.
     * Calling the child components method for the form reseting.
     */
    const resetForm = () => {
      ElMessageBox.confirm(
        `Вы действительно хотите <strong>очистить</strong> форму?`,
        'Внимание',
        {
          type: 'warning',
          cancelButtonText: 'Отмена',
          confirmButtonText: 'Очистить',
          dangerouslyUseHTMLString: true,
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              instance.confirmButtonLoading = true
              instance.confirmButtonText = 'В процессе...'
              formRefParent.value.resetForm()
              done()
              instance.confirmButtonLoading = false
            } else {
              done()
            }
          },
        }
      )
        .then(() => {
          ElMessage({
            type: 'success',
            message: 'Форма очищена',
          })
        })
          .catch(() => {
            ElMessage({
              type: 'info',
              message: 'Отмена сброса',
            })
          })
    }

    /*********************
    * FORM ACTIONS END
    *********************/

    onMounted(() => {
      getEntry()
    })

    return {
      route,
      isEmptyObject,
      // FORM
      entryData,
      formRefParent,
      saveForm,
      resetForm,
      // FORM BUTTONS
      formsAreEqual,
      buttonsLoading,
      saveButtonClick,
    }
  },
})
